export const normalizeProduct = (product) => {
  const images = product?.images.map((image) => image.src)
  let minimumPrice = null
  const variants = product?.variants.map((variant) => {
    const amount = variant.priceV2.amount
    if (minimumPrice) {
      minimumPrice = amount < minimumPrice ? amount : minimumPrice
    } else {
      minimumPrice = amount
    }
    return {
      id: variant.id,
      name: variant.title === "Default Title" ? product.title : variant.title,
      productTitle: product.title,
      priceTxt: `$${variant.priceV2.amount} ${variant.priceV2.currencyCode}`,
      price: variant.priceV2.amount,
      image: variant.image.src,
      available: variant.available,
    }
  })
  return {
    id: product.id,
    name: product.title,
    description: product.description,
    descriptionHTML: product.descriptionHtml,
    price: minimumPrice,
    available: product?.availableForSale || product?.available,
    variants,
    images,
  }
}

export default normalizeProduct
